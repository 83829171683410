import React from "react";
import WindsyAppBar from "../common/appbar";
import Footer from "../common/footer";
import { Container } from "react-bootstrap";
import { useState, useEffect} from "react";

const ContactSales = () => {
    /* const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }
        console.log(event.target[0].value);
        console.log(event.target[1].value);
        console.log(event.target[2].value);
        console.log(event.target[3].value);
        console.log(event.target[4].value);
        setValidated(true);
    };

    const handleSubmitBtn = (event) => {
        
    }

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    let url = "https://restcountries.com/v2/all";

    useEffect(() => {
        fetch(url)
         .then((response) => {
            return response.json();
         }).then((actualData) => {
            setData(actualData);
            setError(null);
         });
       }, []);

    const handlePhone = (value) => {
        console.log(value.target.value);
        if (value.target.value.length < 10) {
            console.log(false);
            return false;
        }
        console.log(true);
        return true;
    } */

    return(
        <div style={{display: 'flex', flexDirection:'column'}}>
            <WindsyAppBar changed="true"/>            
            <div style={{height: '80px'}}></div>
            <Container style={{padding: '50px 50px 0px 50px', fontFamily:'roboto'}}>
                <h1 style={{
                    fontWeight:'700', 
                    fontSize:'3rem',
                    textAlign:'left',
                    color:'#E84E1B'
                }}>
                    Contact Sales
                </h1>
            </Container>
            <Container style={{
                padding:'30px 50px',
                textAlign:'left',
                height:'50vh',
                }}>
                <h4 style={{paddingBottom:'20px'}}>Let's Talk</h4>
                <p>If you interesting in knowing more about our services or would like us to help you out with any wind turbine related issues, please send us an email at <a href='mailto:info@windsy.de'>info@windsy.de</a></p>
                <p>We are looking forward to you!</p>
                
                {/*                 <p style={{paddingBottom:'5px'}}>Select field you would like to have us support you on</p>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group required style={{padding:'0px 20px'}} controlId="validationC00"                                     feedback="You must agree before submitting."
                                    feedbackType="invalid">
                            {['checkbox'].map((type) => (
                                <div key={`${type}`} className="mb-3">
                                <Form.Check
                                    
                                    label="Expert Advisory"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}

                                />
                                <Form.Check
                                    label="Engineering Services"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-2`}
                                />
                                <Form.Check
                                    label="Due Diligence"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-3`}
                                />
                                <Form.Check
                                    label="Project Development"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-4`}
                                />
                                </div>
                            ))}
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                defaultValue=""
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                defaultValue=""
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control required type="email"/>
                            <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group style={{display:'flex', alignItems:'center'}} controlId="formHorizontalPassword">
                            <Col xs={2} lg={3}>
                                <Form.Label >
                                    Phone No.
                                </Form.Label>
                            </Col>
                            <Col xs={3} lg={3}>
                                <Form.Select style={{height:'35px'}} defaultValue="+49">
                                {data &&
                                data.map((country) => (
                                    <option key={country.name}>+{country.callingCodes}</option>
                                ))}
                                </Form.Select>
                            </Col>
                            <Col xs={7} lg={7}>
                                <Form.Control type="text" onChange={handlePhone} />
                            </Col>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} style={{paddingTop:'20px'}} controlId="formGridState">
                            <Form.Label style={{paddingRight:'10px'}}>Country</Form.Label>
                            <Form.Select style={{height:'35px'}} defaultValue="Germany">
                            {data &&
                            data.map((country) => (
                                <option key={country.name}>{country.name}</option>
                            ))}
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Form.Group style={{paddingTop:'20px'}} className="mb-3">
                        <Form.Check
                            required
                            label={<p>I accept the electronic storage of my personal data according to the <a href="/company/data-protection" target="_blank" >data protections</a></p> }
                            feedback="You must agree before submitting."
                            feedbackType="invalid"
                        />
                    </Form.Group>
                    <Button type="submit">Submit form</Button>
                </Form> */}
            </Container>
            <Footer />
        </div>
    );
}

export default ContactSales;