import React from "react";
import { Col, Card } from "react-bootstrap";

const TextCard = (props) => {
    return (
        <Col style={{padding:'20px'}}>
            <Card style={{ 
                width: '13rem', 
                padding:'20px 0px',
                margin:'auto',
            }}>
                <Card.Body>
                    <Card.Title style={{height:'90px',fontWeight:'700',}}>
                        {props.title}
                    </Card.Title>
                    <Card.Text style={{height:'40px', fontWeight:'500', fontSize: '1.2rem'}}>
                        {props.body}
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );
}

export default TextCard;