import React, { useEffect, useState } from "react";
import '../widgets/widget-styles.css';
import { Row, Col, Button } from "react-bootstrap";
import DivImageContainer from "./div-image-container";


const ImageDivContainer =(props) =>{

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        window
        .matchMedia("(min-width: 768px)")
        .addEventListener('change', e => setMatches( e.matches ));
    }, []);


    return (
        <div>
        {matches ? (
        <div style={{display:'flex', justifyContent:'center'}}>
            <div style={{width:'90%', padding: '50px 20px'}}>
            <Row style={{display:'flex', justifyContent:'space-between',width:'100%'}}>
                <div style={{display:'flex', justifyContent:'start', width:'50%'}}>
                    <img src={props.img} style={{width:'80%', height:'40vh', minHeight:'400px', borderRadius: 10}} alt="img" loading="lazy" />
                </div>
                <div  style={{display:'flex', justifyContent:'end', marginLeft:'0', marginRight:'auto', width:'50%'}}>
                    <div style={{width:'80%'}}>
                        <h1 style={{ fontSize: '3rem', fontWeight:'bold', margin: '0 auto', textAlign: 'start', paddingBottom:'20px'}}>
                            {props.header}
                        </h1>
                        {props.body}
                    </div>
                </div>
                </Row>
            </div>
        </div>
        ) : <DivImageContainer 
            header ={props.header}
            body = {props.body}
            img = {props.img}
        /> }
        </div>
    );
}

export default ImageDivContainer;