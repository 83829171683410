import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import '../styles/appbar-styles.css';

const WindsyAppBar = (props) => {

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 976px)").matches
    )

    useEffect(() => {
        window
        .matchMedia("(min-width: 976px)")
        .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        matches ? 
        (
        <div className="fixedElement" id={props.changed ? 'navbar-moved' : 'fixedElement'}>
            <div style={{
                    display:'flex', 
                    justifyContent:'space-between', 
                    alignItems:'center',
                    width:'90%',
                }}>
                <a href="/">
                    <img 
                        src='https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/windsy_logo.svg?alt=media&token=4e2693cf-6d82-47e8-a3aa-ee135936da4b'
                        width='180px'
                        alt='windsy_logo'
                    />
                </a>
                <div style={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        width:'90%',
                        gap:'20px',
                    }}>
                    <a class={props.changed ? 'navbar-main-link navbar-clr-nrml' : 'navbar-main-link navbar-clr-trans'} href="/expert-advisory">Expert Advisory</a>
                    <div className='dropdown'>
                        <span class={props.changed ? 'navbar-main-link navbar-clr-nrml' : 'navbar-main-link navbar-clr-trans'}>Engineering Services</span>
                        <div class="dropdown-content dropdown-menu-center" style={{
                            padding:'20px',
                            flexDirection: 'column',
                            alignItems:'start',
                            gap:'10px',
                        }}>
                            <a className='navbar-sub-link' href="/engg-services/field-issue-resolution">Field Issue Resolution</a>
                            <a className='navbar-sub-link' href="/engg-services/industrialization">Industrialization</a>
                            <a className='navbar-sub-link' href="/engg-services/systems-engineering">Systems Engineering</a>
                            <a className='navbar-sub-link' href="/engg-services/supply-chain-support">Supply Chain Support</a>
                        </div>
                    </div>
                    <a class={props.changed ? 'navbar-main-link navbar-clr-nrml' : 'navbar-main-link navbar-clr-trans'} href="/due-dilligence">Due Diligence</a>
                    <a class={props.changed ? 'navbar-main-link navbar-clr-nrml' : 'navbar-main-link navbar-clr-trans'} href="/project-development">Project Development</a>
                    <div className='dropdown'>
                        <span class={props.changed ? 'navbar-main-link navbar-clr-nrml' : 'navbar-main-link navbar-clr-trans'}>Company</span>
                        <div class="dropdown-content dropdown-menu-center" style={{
                            padding:'20px',
                            flexDirection: 'column',
                            alignItems:'start',
                            gap:'10px',
                        }}>
                            <a className='navbar-sub-link' href="/company/about-us">About us</a>
                            <a className='navbar-sub-link' href="/company/careers">Careers</a>
                            {/* <a className='navbar-sub-link' href="/company/team">Team</a> */}
                            <a className='navbar-sub-link' href="/company/contact-us">Contact us</a>
                            {/* <a className='navbar-sub-link' href="https://www.windsy-solar.de" target='_blank'>Solar</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        ): 
        (
        <Navbar collapseOnSelect expand="lg" sticky="top"
        id={props.changed ? 'navbar-moved' : 'navbar-static'}>
            <Container fluid style={{width:'90%', backgroundColor: 'transparent'}} >
                <Navbar.Brand href="/">
                    <img 
                        src='https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/windsy_logo.svg?alt=media&token=4e2693cf-6d82-47e8-a3aa-ee135936da4b'
                        width='180px'
                        alt='windsy_logo'
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto justify-content-center flex-grow-1" 
                        style={{
                            fontSize:'14px',
                            fontWeight:'500', gap: '20px',
                        }}
                    >
                        <Nav.Link href="/expert-advisory" id="collapsible-nav">Expert Advisory</Nav.Link>
                        
                        <NavDropdown title="Engineering Services" 
                                    id="collapsible-nav"
                        >
                            <NavDropdown.Item id='nav-dropdown-item' href="/engg-services/field-issue-resolution">Field Issue Resolution</NavDropdown.Item>
                            <NavDropdown.Item id='nav-dropdown-item' href="/engg-services/industrialization">Industrialization</NavDropdown.Item>
                            <NavDropdown.Item id='nav-dropdown-item' href="/engg-services/systems-engineering">Systems Engineering</NavDropdown.Item>
                            <NavDropdown.Item id='nav-dropdown-item' href="/engg-services/supply-chain-support">Supply Chain Support</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="/due-dilligence" id="collapsible-nav">Due-Diligence</Nav.Link>
                        <Nav.Link href="/project-development" id="collapsible-nav">Project Development</Nav.Link>
                        <NavDropdown title="Company" id="collapsible-nav">
                            <NavDropdown.Item id='nav-dropdown-item' href="/company/about-us">About us</NavDropdown.Item>
                            <NavDropdown.Item id='nav-dropdown-item' href="/company/careers">Careers</NavDropdown.Item>
                            {/* <NavDropdown.Item id='nav-dropdown-item' href="/company/team">Team</NavDropdown.Item> */}
                            <NavDropdown.Item id='nav-dropdown-item' href="/company/contact-us">Contact us</NavDropdown.Item>
                            <NavDropdown.Item id='nav-dropdown-item' href="https://www.windsy-solar.de" target='_blank'>Solar</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        )        
    );
}

export default WindsyAppBar;