import React from "react";
import WindsyAppBar from "../common/appbar";
import Footer from "../common/footer";
import { Container } from "react-bootstrap";
import JobPostEN from "../widgets/job-post-en";
import JobPostDE from "../widgets/job-post-de";

const Careers = () => {
    return (
        <div>
            <WindsyAppBar changed="true" />
            <div style={{ height: '80px' }}></div>
            <Container style={{ padding: '50px 30px 0px 30px' }}>
                <h1 style={{ fontWeight: '700', fontSize: '3rem', color: '#E84E1B' }}>
                    Careers
                </h1>
                <div>
                    <img
                        alt='careers'
                        width='50%'
                        src='https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/launcher%2Fcareers.svg?alt=media&token=6a9a3194-3fee-4ebe-98c0-2f2e085badc8'
                    />
                </div>
                <p style={{
                    textAlign: 'center',
                    textJustify: 'inner-word',
                    fontWeight: '400',
                    fontSize: '1.2rem',
                    padding: '20px 10px',
                }}>
                    Thank you for your interest in Windsy GmbH <br /><br />
                    To move forward we are always looking for hands-on, motivated and innovative colleagues <br /><br />
                    See the vacancies below or send us your unsolicited application directly to <a href="mailto:career@windsy.de">career@windsy.de</a> at any time<br /><br />
                </p>
            </Container>
            <Container style={{
                paddingBottom: '50px',
            }}>
                <div>
                    <div style={{ textAlign: 'start', fontWeight: 'bold', fontSize: '2rem', color: '#E84E1B', padding: '0px 0px 0px 0px' }}>
                        WIND
                    </div>
                </div>
                <JobPostEN
                    title='SENIOR ELECTRICAL ENGINEER (M/W/D) - FULL TIME'
                    body='To support our team, we are looking for a person for our office in Kiel as soon as possible'
                    responsibility={
                        <ul>
                            <li>Project specific design of electrical systems </li>
                            <li>Troubleshooting and analyzing technical issues in depth to understand core problems and provide sound solutions in large NC projects </li>
                            <li>Perform testing of prototypes, analyze and implement the design to meet product performance specifications </li>
                            <li>Collaborate with customers, vendors and partners to improve electrical systems from all perspectives </li>
                        </ul>
                    }
                    profile={
                        <ul>
                            <li>Bachelor/Master in Electrical/Electronic engineering with, ideally, 5+ years of proven experience in a similar position</li>
                            <li>Familiar with wind farm system architectures and their simulation in PSCAD and PSSE </li>
                            <li>Experience with designing circuits for sensors, actuators and digital interfaces </li>
                            <li>Possess good interpersonal skills and enjoys working in a collaborative team environment </li>
                            <li>Possess excellent interpersonal skills </li>
                            <li>Curiosity and willingness to take up new technologies and business fields </li>
                            <li>Good written communication skills and the ability to work in direct parallel with a global team </li>
                            <li>Fluent in both German and English </li>
                        </ul>
                    }
                />
                <JobPostDE
                    title='IT SUPPORT (M/W/D) IN KIEL/REMOTE'
                    body='Die Windsy GmbH fokussiert sich auf Design, Entwicklung und Industrialisierung von Windparks und Windkraftanlagen. Darüber hinaus bietet Windsy seinen Kunden projektspezifische und technische Lösungen aus den Bereichen Windpark Optimierung, Fehlerbehebung, Service und Wartung.'
                    responsibility={
                        <ul>
                            <li>First Level Support</li>
                            <li>Installation, Konfiguration und Wartung von IT-Systemen und -Anwendungen</li>
                            <li>Beschaffung und Installation von Hard- und Software</li>
                            <li>Verwalten und organisieren der Zugriffsrechte für Benutzerkonten und Lizenzen in Zusammenarbeit mit der Group IT</li>
                            <li>Datensicherung- und Speicherung</li>
                            <li>Selbstständige Analyse und Lösung von IT-Problemen</li>
                            <li>Entwicklung und Mitarbeit an KVP-Projekten zur Verbesserung der IT-Zuverlässigkeit und Performance</li>
                            <li>Beratung und Schulung der Anwender:innen</li>
                        </ul>
                    }
                    profile={
                        <ul>
                            <li>Studium der Informatik oder motivierte/r Quereinsteiger/in</li>
                            <li>Grundlegendes Verständnis von IT-Umgebungen</li>
                            <li>Kentnisse im Bereich First Level Support</li>
                            <li>Skills im Microsoft Client und Serverumfeld</li>
                            <li>Selbstständige, sehr strukturierte Arbeitsweise</li>
                            <li>Erste Erfahrungen im Support von Windows-Systemen, insbesondere der Microsoft 365 Umgebungen inklusive Power Automate und Power Apps, von Vorteil</li>
                            <li>Hohe IT-Affinität und Kenntnisse im Bereich der Netzwerktechnik</li>
                            <li>Teamfähigkeit und ein hohes Maß an Eigeninitiative sowie Verantwortungsbewusstsein</li>
                            <li>Python Grundkenntnisse</li>
                            <li>Fließendes Englisch für die sichere Kommunikation in einer internationalen Gruppe</li>
                        </ul>
                    }
                />
                <JobPostDE
                    title='WERKSTUDENT IT SUPPORT (M/W/D) IN KIEL/REMOTE'
                    body='Die Windsy GmbH fokussiert sich auf Design, Entwicklung und Industrialisierung von Windparks und Windkraftanlagen. Darüber hinaus bietet Windsy seinen Kunden projektspezifische und technische Lösungen aus den Bereichen Windpark Optimierung, Fehlerbehebung, Service und Wartung.'
                    responsibility={
                        <ul>
                            <li>First Level Support</li>
                            <li>Installation, Konfiguration und Wartung von IT-Systemen und -Anwendungen</li>
                            <li>Beschaffung und Installation von Hard- und Software</li>
                            <li>Verwalten und organisieren der Zugriffsrechte für Benutzerkonten und Lizenzen in Zusammenarbeit mit der Group IT</li>
                            <li>Datensicherung- und Speicherung</li>
                            <li>Selbstständige Analyse und Lösung von IT-Problemen</li>
                            <li>Entwicklung und Mitarbeit an KVP-Projekten zur Verbesserung der IT-Zuverlässigkeit und Performance</li>
                            <li>Beratung und Schulung der Anwender:innen</li>
                        </ul>
                    }
                    profile={
                        <ul>
                            <li>Studium der Informatik oder motivierte/r Quereinsteiger/in</li>
                            <li>Grundlegendes Verständnis von IT-Umgebungen</li>
                            <li>Kentnisse im Bereich First Level Support</li>
                            <li>Skills im Microsoft Client und Serverumfeld</li>
                            <li>Selbstständige, sehr strukturierte Arbeitsweise</li>
                            <li>Erste Erfahrungen im Support von Windows-Systemen, insbesondere der Microsoft 365 Umgebungen inklusive Power Automate und Power Apps, von Vorteil</li>
                            <li>Hohe IT-Affinität und Kenntnisse im Bereich der Netzwerktechnik</li>
                            <li>Teamfähigkeit und ein hohes Maß an Eigeninitiative sowie Verantwortungsbewusstsein</li>
                            <li>Python Grundkenntnisse</li>
                            <li>Fließendes Englisch für die sichere Kommunikation in einer internationalen Gruppe</li>
                        </ul>
                    }
                />
                {/*                     <JobPostEN
                        title='CHIEF/SENIOR CONSULTANT TECHNICAL DUE DILIGENCE (M/W/D) - FULL TIME'
                        body='As our new Chief or Senior Consultant you will be part of our Technical Due Diligence competence and advisory lead. You will take on a key role as subject matter expert shaping and enhancing our service delivery. Our assignments are of a diverse nature with both well-known and new market players, joint ventures and investment funds, and cover a broad scope of services as well as potential combination with our engineering and EPCM projects.'
                        responsibility={
                        <ul>
                            <div>As part of a multidisciplinary engineering team, you will take on diverse tasks and responsibilities in international projects of varied complexity and scale, such as: </div>
                            <br />
                            <li>Diverse due diligence projects for both fixed-bottom (onshore/offshore) and floating wind in a variety of development stages</li>
                            <li>Project management or contribution as a specialist, of due diligence projects, ensuring the results are concise, insightful and impactful </li>
                            <li>Establishing and improving due diligence processes (such as interface to risk assessment competences) and training for a rapidly growing team </li>
                            <li>Leading new internal initiatives to leverage due diligence experience into other areas</li>
                            <li>Leading Windsy expert consultancy teams and technical experts in major non-conformity projects</li>
                        </ul>
                        }
                        profile={
                        <ul>
                            <li>Bachelor’s degree in a relevant discipline; a master’s degree or other advanced degree is preferred. </li>
                            <li>A minimum of 10 years (Chief) / 4-10 years (Senior) of relevant professional experience in consulting or project management </li>
                            <li>Substantial / extensive experience in offshore wind project development, execution and / or operation within a project developer organization or in an Owner’s Engineer role within the relevant field </li>
                            <li>Profound industry knowledge and a thorough understanding of design and engineering, project economics, interfaces, risks and commercial aspects of offshore wind farm development</li>
                            <li>Experience in negotiating complex construction contracts</li>
                            <li>Contacts to an established network of clients for due diligence, ideally on seller, buyer and lender sides </li>
                            <li>High level of proficiency in written and spoken English; additional languages are beneficial</li>
                            <li></li>
                        </ul>
                        }
                    />
                    <JobPostEN
                        title='CAD INTEGRATOR (M/W/D) - FULL TIME'
                        body='To support our team, we are looking for a person for our office in Kiel as soon as possible'
                        responsibility={
                        <ul>
                            <li>Accurate CAD assemblies of wind turbines and wind turbine subsystems</li>
                            <li>Ownership of assembly structures and the part integration workflow</li>
                            <li>Detailed engineering drawings / specifications for manufacturing and procurement.</li>
                            <li>Engineering support for the development of retrofits, methods, components, and assemblies</li>
                            <li>Assistance to the Sales &amp; Engineering Team with communication and technical support.</li>
                            <li>Drafting expertise and support to Sales &amp; Engineering teams.</li>
                            <li>Maintain relationships with Windsy customers</li>
                            <li>This position will require minor domestic or international travel</li>
                        </ul>
                        }
                        profile={
                        <ul>
                            <div style={{fontWeight: "bold"}}>Qualifications / Requirements</div>
                            <li>3 years of CAD drafting experience (preferably Solid Works).</li>
                            <li>Experience in the principles, practices, and procedures of engineering drafting</li>
                            <li>Good knowledge and understanding of technical drawing standards and detailing</li>
                            <li>Ability to manage their workflow and work well within a team environment</li>
                            <br />
                            <div style={{fontWeight: "bold"}}>Desired Characteristics</div>
                            <li>Basic knowledge on wind turbine technologies</li>
                            <li>Basic knowledge about welded design, bolt connections and castings</li>
                            <li>A team player striving to achieve the best result for our customer</li>
                            <li>Flexibility in tackling new challenges</li>
                        </ul>
                        }
                    />
                    <JobPostEN
                        title='TECHNICAL PROJECT MANAGER - EPCM PROJECTS (M/W/D) - FULL TIME'
                        body='The technical project manager is responsible for the project management of EPCM, engineering and product development projects. The role includes all project management and leadership tasks for the project team as well as the customer communication including weekly status reports, scheduling, and the negotiation of scope extensions.'
                        responsibility={
                        <ul>
                            <li>Coordinating and executing all aspects of an Engineering, Construction and Project Management (EPCM) projects</li>
                            <li>Coordinating overall scope, schedule, budget, security and project team management</li>
                            <li>Run and lead project related meetings for both internal and external stakeholders</li>
                            <li>Preparation, implementation, and follow-up as well as documentation of meetings and workshops</li>
                            <li>Structuring, optimizing and maintenance of work processes, monitoring of project activities and tasks</li>
                            <li>Ensuring an efficient flow of information between all project stakeholders</li>
                            <li>Interface function between engineering, construction and infrastructure team</li>
                            <li>Creation of status reports, progress reports and other ad-hoc reports and presentations</li>
                            <li>Review Request for payments, change orders and claim reports</li>
                            <li>Recognizing and documenting project risks and coordinating risk mitigation plans</li>
                        </ul>
                        }
                        profile={
                        <ul>
                            <div style={{fontWeight: "bold"}}>Qualifications / Requirements</div>
                            <li>Bachelor's or master's degree in engineering or a related subject </li>
                            <li>Experienced in the management of medium to large industrial projects </li>
                            <li>Advanced MS Office, presentation and reporting skills </li>
                            <li>Ability to communicate effectively with diverse audiences, from the site engineer to the CXO level </li>
                            <li>Good command of English and German </li>
                            <li>Willingness to travel and to carry out international projects </li>
                            <br />
                            <div style={{fontWeight: "bold"}}>Desired Characteristics</div>
                            <li>A structured, precise and independent way of working  </li>
                            <li>Absolute reliability and a sense of responsibility  </li>
                            <li>A team and goal-oriented way of working  </li>
                            <li>Excellent organizational and negotiating skills </li>
                            <li>A friendly, self-confident, and customer-oriented demeanor </li>
                        </ul>
                        }
                    />
                    <JobPostEN
                        title='LOAD SIMULATION ENGINEER (M/W/D) - FULL TIME'
                        body='The Engineer wind turbine loads simulation is responsible for all aspects of the generation of loads results by use of in-house simulation tools suite Flex5. His main responsibility is the simulation model set, execution of simulations, compilation of meaningful results and generation of required documentation for internal and external purposes.'
                        responsibility={
                        <ul>
                            <div>Development of wind turbine system models and simulation environment for dynamic behavior, loads & performance simulations like:</div>
                            <li>Modeling for mechanical load spectra </li>
                            <li>Optimization for wind turbine operability </li>
                            <li>Power output and load level optimization </li>
                            <li>Execution of the wind turbine systems modeling and load simulations for dynamic behavior and performance for wind turbines </li>
                            <li>Preparation, execution and follow up of design reviews </li>
                            <li>Generate required documentation material and reporting </li>
                            <li>Drive the improvements of wind turbine system simulation technologies towards improved performance and operability </li>
                            <li>Drive the turbine control algorithm optimization and controller integration </li>
                            <li>Identify and implement key technologies that can lead to differentiated next generation wind turbine simulation tools </li>
                            <li>Maintain an active role in developing and defining the state of the art of wind turbine loads and controls tool suite. </li>
                            <li>Maintain relationships with Windsy customers </li>
                            <li>This position will require minor domestic or international travel</li>
                        </ul>}
                        profile={
                        <ul>
                            <div style={{fontWeight: "bold"}}> Qualifications/Requirements</div>
                            <li>Wind turbine systems knowledge</li>
                            <li>Master of Science degree in renewable energies</li>
                            <li>Some experience in the simulation tools like FLEX5, BLADED or OpenFAST</li>
                            <li>Proven knowledge in certification guidelines such as IEC61400- 1</li>
                            <li>Experience in Matlab/Simulink</li>
                            <li>Strong interpersonal and communication skills</li>
                            <li>Fluent in English</li>
                            <br />
                            <div style={{fontWeight: "bold"}}>Desired Characteristics</div>
                            <li>Basic knowledge on codes and standards for wind turbine certification in general</li>
                            <li>A team player striving to achieve the best result for our customer</li>
                            <li>Familiarity with a variety of engineering areas</li>
                        </ul>}
                    />
                    <JobPostDE 
                        title='SOFTWARE DEVELOPER (WERKSTUDENT) - PART TIME'
                        body='Zur Unterstützung unseres Teams suchen wir für unser Büro in Kiel zum nächstmöglichen Zeitpunkt eine'
                        responsibility={
                        <ul>
                            <li>Eigenverantwortliche und kundenorientierte Bearbeitung und Koordination von Projekten</li>
                            <li>Verlängerter Arm unserer Kunden: Technical Support, Fehlerprojekte und Retrofit Lösungen</li>
                            <li>Erarbeiten innovativer Lösungen für die spezifischen Kundenanforderungen</li>
                            <li>Mitwirken bei der Erstellung von technischen Dokumentationen (Produktanforderungen, technischen Spezifikationen,Arbeitsanweisungen und Prüfdokumenten)</li>
                            <li>Auslegung und Berechnung von kritischen Bauteilen</li>
                            <li>Kommunikation mit unseren Auftraggebern Mechanical Engineer Technical Services (m/w/d)</li>
                        </ul>}
                        profile={
                        <ul>
                            <li>Techniker oder Hochschulabschluss in Informatik</li>
                            <li>Berufserfahrung in der Wind Industrie von Vorteil</li>
                            <li>Selbstständiges und Eigenverantwortliches Arbeiten</li>
                            <li>Hohe Team- und Kommunikationsfähigkeit</li>
                            <li>Besonnenheit/Belastbarkeit auch in kritischen Situationen</li>
                            <li>Strukturiertes Arbeiten und effizientes Aufgaben- und Zeitmanagement</li>
                            <li>Hohe interkulturelle Kompetenz und lösungsorientiertes Denken/Handeln</li>
                            <li>Hohe Kundenorientierung</li>
                            <li>Sichere Englischkenntnisse in Wort und Schrift</li>
                            <li>Einsatzbereitschaft / Flexibilität</li>
                            <li>Bereitschaft für gelegentliche Reisen</li>
                        </ul>}
                        />*/}
                {/*                     <JobPostDE 
                        title='WIND ENERGY (WERKSTUDENT) - PART TIME'
                        body='Zur Unterstützung unseres Teams suchen wir für unser Büro in Kiel zum nächstmöglichen Zeitpunkt eine'
                        responsibility={
                        <ul>
                            <li>Erarbeiten innovativer Lösungen für die spezifischen Kundenanforderungen</li>
                            <li>Mitwirken bei der Erstellung von technischen Dokumentationen (Produktanforderungen, technischen Spezifikationen, Arbeitsanweisungen und Prüfdokumenten)</li>
                            <li>Auslegung und Berechnung von Bauteilen für Retrofit-Lösungen</li>
                            <li>Analysieren von Betriebsdaten von Windkraftanalagen</li>
                            <li>Unterstützung bei der Root Cause Analyse und Retrofit-Entwicklung in NC-Projekten</li>
                            <li>Konzipieren und entwickeln innovativer Produkte bis zur Serialisierung</li>
                        </ul>}
                        profile={
                        <ul>
                            <li>Immatrikulierter Student im Bereich der Ingenieurswissenschaften</li>
                            <li>Flexibilität im Hinblick auf Aufgaben und Offenheit neue Herausforderungen anzugehen</li>
                            <li>Erste Erfahrungen im Post-Processing von Daten mit Excel oder MATLAB</li>
                            <li>Erste Erfahrungen in der systematischen Nachweisführung von Maschinenelementen</li>
                            <li>Gute Kommunikationsfähigkeit und fließende Deutsch- und Englischkenntnisse in Wort und Schrift</li>
                        </ul>}
                    /> */}
            </Container>
            <Footer />
        </div>
    );
}
export default Careers;