import React from "react";
import WindsyAppBar from "../common/appbar";
import Footer from "../common/footer";
import { Container, Row, Card, Button } from "react-bootstrap";
import WindsyLocations from "../widgets/locations";
import { useNavigate } from "react-router-dom";

const ContactUs = ()=> {
    const navigate = useNavigate();

    const navigateHome = () => {
        // 👇️ navigate to /
        navigate('/company/contact-us/sales');
      };

    return (
        <div>
            <WindsyAppBar changed="true"/>
            <div style={{height: '80px'}}></div>
            <Container style={{padding:'50px', fontFamily:'roboto'}}>
                <h1 style={{
                    fontWeight:'700', 
                    fontSize:'3rem',
                    display:'flex',
                    justifyContent:'start',
                    color:'#E84E1B',
                    }}>
                    Contact us
                </h1>
            </Container>
            <Container style={{
                padding:'30px 50px',
                textAlign:'left',
                height:'45vh',
                }}>
                <h4 style={{paddingBottom:'20px'}}>Let's Talk</h4>
                <p>If you interesting in knowing more about our services or would like us to help you out with any wind turbine related issues, please send us an email at <a href='mailto:info@windsy.de'>info@windsy.de</a></p>
                <p>We are looking forward to you!</p>
            </Container>
{/*             <Container>
                <Row style={{
                    display:'flex',
                    width:'100%',
                    height:'100%',
                    justifyContent:'space-evenly',
                    padding:'10px 0px',
                    margin:'auto',
                }}>
                    <Card style={{
                        width:'500px',
                        height:'500px',
                        padding:'0px 60px',
                        display:'flex',
                        borderRadius:'20px',
                        margin:'20px 20px',
                        boxShadow:'1px 1px 1px -0px rgba(0, 0, 0, 0.2)',
                        }}>
                        <Card.Body>
                                <img
                                alt='contact_sales'
                                src='https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/launcher%2Fstartup_aboutus.svg?alt=media&token=e08bd214-6a7c-4baa-bf4c-0ce8182aa670'
                                />
                            <Card.Title>
                                Sales
                            </Card.Title>
                            <Card.Text>
                                Learn how Windsy can power your business    
                            </Card.Text>
                            <Button style={{
                                borderRadius:'20px',fontWeight:'bold', padding:'5px 20px'
                            }} variant="primary"
                            onClick={navigateHome}
                            >
                                Contact Sales
                            </Button>
                        </Card.Body>
                    </Card>
                    <Card style={{
                        width:'500px',
                        height:'500px',
                        padding:'0px 60px',
                        display:'flex',
                        borderRadius:'20px',
                        margin:'20px 20px',
                        boxShadow:'1px 1px 1px -0px rgba(0, 0, 0, 0.2)',
                        }}>
                        <Card.Body>
                                <img
                                alt='contact_support'
                                src='https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/launcher%2Fstartup_aboutus.svg?alt=media&token=e08bd214-6a7c-4baa-bf4c-0ce8182aa670'
                                />
                            <Card.Title>
                                Support
                            </Card.Title>
                            <Card.Text>
                                Contact our support team to guide you
                            </Card.Text>
                            <Button style={{
                                borderRadius:'20px', fontWeight:'bold', padding:'5px 20px'
                            }} variant="primary">
                                Contact Support
                            </Button>
                        </Card.Body>
                    </Card>
                </Row>
            </Container> */}
            <Container>
                <WindsyLocations />
            </Container>
            <Footer />
        </div>
    );
}
export default ContactUs;