import React from "react";
import WindsyAppBar from "../common/appbar";
import Footer from "../common/footer";
import GetInTouchWithUs from "../common/get-in-touch";
import DivImageContainer from "../widgets/div-image-container";
import HeaderDiv from "../widgets/header-div";
import ImageDivContainer from "../widgets/image-div-container";
import ScopeOfWork from "../widgets/scope-of-work";
import SubHeaderDiv from "../widgets/sub-header-div";

const SystemsEngineering = ()=> {
    return (
        <div>
            <WindsyAppBar changed="true"/>
            <div style={{height: '80px'}}></div>
            <HeaderDiv 
                title="Systems Engineering"
                img = 'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fsys_eng%2Fsystems%20engineering.jpg?alt=media&token=84e5fa1f-2f78-45d5-af39-e20c266b509c'
            />
            <SubHeaderDiv
                body = {
                    <div>
                        <div>
                            Our systems engineering supports a fast and reliable development of products. Risks are mitigated proactively and complemented with product and process FMEA‘s
                        </div>
                        <br></br>
                        <div>
                            Along a V-model approach we identify critical requirements and ensure their effective validation. Digital twins help us to frontload learning curves
                        </div>
                        <br></br>
                        <div>
                            We like to benchmark – to target the right cost and to develop your product superior to market
                        </div>
                    </div>
                }
            />
            {/* Scope of Work */}
            <ScopeOfWork
                scope = "Expert Tailored Engineering Services"
            />
            <DivImageContainer
                header='Technical Specifications'
                img={'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fsys_eng%2FIMG_0937.JPG?alt=media&token=407d7ad8-ee26-47b7-974d-14d93f191243'}
                body={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px'}}>
                        <li>Benchmarking & Design-to-Cost</li>
                        <li>Requirements management</li>
                        <li>Breakdown to component specifications</li>
                        <li>Development of mechanical and electrical systems</li>
                        <li>System integration</li>
                    </ul>
                }
            />
            <ImageDivContainer
                header='Simulation & Validation'
                img={'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fsys_eng%2Fsystems%20engineering.jpeg?alt=media&token=3d2cba64-3398-44e2-b32b-298636a3790f'}
                body={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px', marginLeft:'auto', marginRight:'0'}}>
                        <li>Digital Twin & Simulation</li>
                        <li>Aero-elastic Simulation of wind turbine loads</li>
                        <li>Simulation & control features for floater</li>
                        <li>Verification and validation along a V-model</li>
                        <li>Certification and compliance</li>
                        <li>Risk management, Failure Mode Effect Analysis (FMEA) & Advanced Product Quality Planning (APQP)</li>
                    </ul>
                }
            />
            <GetInTouchWithUs />
            <Footer />
        </div>
    );
}
export default SystemsEngineering;