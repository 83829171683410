import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const JobPostDE = (props) => {
    return (
        <div style={{padding:'4px 0px'}}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{backgroundColor:'#E94E1B'}}
                >
                    <Typography style={{
                        color:'white', 
                        fontWeight:'bold', 
                        fontSize:'1.1rem',
                        textAlign:'left',
                    }}>
                        {props.title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{
                    backgroundColor:'#1D70B8',
                    color:'white',
                    textAlign:'start',
                    wordWrap:'break-word',
                }}>
                    <Typography>
                    <p>{props.body}</p>
                    <h6>Deine Aufgaben</h6>
                    {props.responsibility}
                    <h6>Dein profil</h6>
                    {props.profile}
                    <h6>Windsy ist der richtige Ort!</h6>
                    <ul>
                        <li>… Das, was im Energiesektor als nächstes kommt, bedeutet für uns</li>
                         <li>Mit unseren Dienstleistungen und Produkten die Energetisierung und Digitalisierung der Welt vorantreiben</li>
                         <li>Teil eines kleinen begeisterten Teams von Denkern und Machern zu sein </li>
                         <li>Internationale Erfahrung in einem dynamischen Umfeld mit direktem Kundenkontakt</li>
                         <li>Flexible Arbeitszeiten, die eine Win-Win-Situation ermöglichen</li>
                         <li>Attraktives Paket</li>
                    </ul>                       
                    <p>
                        Bitte senden Sie uns Ihren Lebenslauf und ein Anschreiben als Bewerbung an <a style={{color:'orange'}}href="mailto:career@windsy.de">career@windsy.de</a>.
                    </p>
                    <p> 
                        Mit dem Absenden Ihrer Bewerbung stimmen Sie der elektronischen Speicherung Ihrer personenbezogenen Daten gemäß den <a style={{color:'orange'}} href="/company/data-protection" target="_blank" >Datenschutzbestimmungen</a> zu 
                    </p>
                    <p>
                        Wir freuen uns auf Ihre Bewerbung - wir schätzen Vielfalt! Sei so wie du bist!
                    </p>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default JobPostDE;