import React from "react";
import { Container } from "react-bootstrap";
import WindsyAppBar from "../common/appbar";
import Footer from "../common/footer";

const DataProtection = () => {
    return (
        <div style={{
                display:'flex', 
                height:'100vh',
                flexDirection:'column',
                justifyContent:'space-between',
            }}>
            <WindsyAppBar changed="true"/>
            <div style={{height: '80px'}}></div>
            <Container style={{
                    height:'100vh',
                    textAlign:'left',
                    padding:'10px 70px'
                }}>
                <h1 style={{
                    fontWeight:'700', 
                    fontSize:'3rem',
                    padding:'20px 0px',
                    color:'#E84E1B',
                }}>Your privacy is safe with us</h1>
                <p>
                    Your privacy and safety of personal data is very important in all our business processes. With the highest organizational and technical safety measures available we make sure that your data are protected from:
                </p>
                <ul>
                    <li>accidental or intential manipulation</li>
                    <li>lost or destruction</li>
                    <li>non-authorized access</li>
                </ul>
                <p>
                    These safety measures are updated regulary and are always up-to-date with the latest technological standard. (For jobs only) If we can offer you no job at present, your data will be stored in our database for future job opportunities according to your individual request. If you want to delete your application, please send us an email to <a href='mailto:career@windsy.de'>career@windsy.de</a> requesting the deletion of your application. Your data will only be used inhouse in the recruiting department. No third party will have access to it.
                </p>
            </Container>
            <Footer />
        </div>
    );
}

export default DataProtection;

// Can use this text later if we use a portal for job applications
//If you want to delete your application, please use the option "cancel" in the menu "application" that is available after login. To cancel your portal account, please choose "cancel portal account" after you have logged in. 