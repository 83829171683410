import React , { useEffect, useState } from "react";
import '../widgets/widget-styles.css';
import { Row, Col } from "react-bootstrap";

const DivOneRowTwoColumns = (props)=> {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        window
        .matchMedia("(min-width: 768px)")
        .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <div style={{padding: '50px 20px'}}>
            {matches ? (
                <div style={{display:'flex', justifyContent:'space-between', margin:'0 auto',
                 width:'90%'}}>
                    <Row>
                        <div  style={{
                                display: 'inline-block',
                                width: '10px',
                                background: '#E94E1B',
                        }}>
                        </div>
                        <h1 style={{ fontSize: '3rem', fontWeight:'bold', margin: 'auto 0', textAlign: 'start', wordWrap:'break-word', padding:'20px', width:'70%'}}>
                            Services we offer
                        </h1>
                    </Row>
                    <Col xs={10} sm={1} md={6} lg={5} style={{padding:'0 0 0 0px'}}>
                        <div style={{overflow:'auto', margin:'auto', border:'8px solid #E94E1B', padding:'30px 20px'}}>
                            {props.body}
                        </div>
                    </Col>
                </div>
            ) : (
                <div>
                    <Row style={{padding: '20px'}}>
                        <div  style={{
                                display: 'flex',
                                width: '10px',
                                height: '50vh',
                                background: '#E94E1B',
                        }}>
                        </div>
                        <h1 style={{ fontSize: '3rem', fontWeight:'bold', margin: 'auto', textAlign: 'start', wordWrap:'break-word', padding:'20px', width:'90%'}}>
                            Services we offer
                        </h1>
                    </Row>
                    <div style={{margin:'auto', border:'8px solid #E94E1B', padding:'20px'}}>
                        {props.body}
                    </div>
                </div>
            )}
        </div>
    );
}

export default DivOneRowTwoColumns;