import React from 'react';
import { Parallax } from 'react-parallax';

const HeaderDiv = (props) => {
    return (
        <div>
            <Parallax         
                bgImage={props.img}
                bgImageAlt="the dog"
                strength={-200}
            >
                <div style={{display:'flex', 
                            height: '500px', 
                            alignItems:'center', 
                            justifyContent:'center', 
                            fontWeight: 'bold', 
                            fontSize:'3rem',
                            color: 'white',
                            padding:'0px 20px'
                }}>
                {props.title}
                </div>
            </Parallax>
        </div>
    );
}

export default HeaderDiv;