import React from "react";
import { useState, useEffect } from "react";
const WindsyLocations = () => {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
      )
    
      useEffect(() => {
        window
        .matchMedia("(min-width: 768px)")
        .addEventListener('change', e => setMatches( e.matches ));
      }, []);

    return(
        <div>
            {matches && (
                <div style={{
                    position: 'relative',
                    padding: '100px 0',
                    width: '100%',
                    height:'100%',
                }}>
                <div style={{
                    width:'100%',
                    height:'250px',
                    padding:'20px',
                    display:'flex',
                    justifyContent:'space-around',
                    position:'absolute',
                    }}>
                    <div style={{
                        position: 'relative',
                        width:'300px',
                        backgroundColor: '#E94E1B',
                        border: '3px solid white',
                        padding: '15px 30px',
                        textAlign: 'center',
                        fontSize: '0.9rem',
                        color: 'white',
                    }}>
                        <h4>KIEL</h4>
                        <h6>Technical Office</h6>
                        <p>
                            Stadtparkweg 12, <br />
                            24106, Kiel, <br />
                            Germany
                        </p>
                        <a style={{
                            color: 'white',
                            textDecoration: 'underline',
                        }}
                        href='https://goo.gl/maps/vuquU2d6HfQw6Vfr9'>Google Maps</a>
                    </div>
                </div>
                <img 
                    style= {{
                        padding: '0px 0',
                    }}
                    alt='Kiel'
                    width='100%'
                    src='https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/contact%2Fcontact.jpg?alt=media&token=e8cb2965-defb-4d18-9e23-ce554ff48808'
                />
            </div>)}
        </div>
    );
}

export default WindsyLocations;