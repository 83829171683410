import React from "react";
import { Row, Col, Card, Button} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import '../styles/home-styles.css';

const WhatWeOffer = ()=> {
    const navigate = useNavigate();

    return (
        <div style={{padding: '70px 30px', backgroundColor: '#1D70B8'}}>
            <div style={{paddingBottom:'30px'}}>
                <h1 style={{ 
                    fontSize: '1.5rem',
                    fontWeight: '700', 
                    margin: '0 auto', 
                    textAlign: 'center', 
                    paddingBottom:'20px',
                    color:'#97BDD9',
                }}>
                    WHAT WE OFFER
                </h1>
                <p style={{textAlign:'center', color:'white', fontSize:'1.5rem', width:'70%', margin:'auto',}}>
                Best in class services and solutions through entire life cycle of wind farms by bridging technical, commercial and financial disciplines
                </p>
            </div>
            <Row style={{padding:'10px'}}>
                <Col style={{padding:'20px'}}>
                    <Card style={{ 
                        width: '18rem', 
                        padding:'20px 0px',
                        margin:'auto',
                    }}>
                        <Card.Body>
                            <Card.Title style={{height:'100px',fontWeight:'700', fontSize:'2rem',}}>
                                Engineering Services
                            </Card.Title>
                            <Card.Text>
                                Expert Tailored Engineering Services
                            </Card.Text>
                            <Button
                                variant="flat"
                                style={{
                                    backgroundColor:'#E94E1B',
                                    color:'white',
                                }}
                                href="/engg-services/field-issue-resolution">
                                Explore Now >
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col style={{padding:'20px'}}>
                    <Card style={{ width: '18rem', padding:'20px 0px', alignItems:'start', margin:'auto'}}>
                        <Card.Body>
                            <Card.Title style={{ height:'100px',fontWeight:'700', fontSize:'2rem',}}>
                                Due Diligence
                            </Card.Title>
                            <Card.Text style={{fontWeight:'400', fontSize:'1rem',}}>
                                Expert Tailored Consultancy Services
                            </Card.Text>
                            <Button                        
                                variant="flat"
                                style={{
                                    backgroundColor:'#E94E1B',
                                    color:'white',
                                }}
                                href="/due-dilligence">Explore Now ></Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col style={{padding:'20px'}}>
                    <Card style={{ width: '18rem', padding:'20px 0px', alignItems:'start', margin:'auto'}}>
                        <Card.Body>
                            <Card.Title style={{ height:'100px',fontWeight:'700', fontSize:'2rem',}}>
                                Project Development
                            </Card.Title>
                            <Card.Text>
                                Expert Tailored Management Services
                            </Card.Text>
                            <Button
                                variant="flat"
                                style={{
                                    backgroundColor:'#E94E1B',
                                    color:'white',
                                }}                            
                                href="/project-development">
                                    Explore Now >
                                </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default WhatWeOffer;