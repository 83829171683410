import React from "react";
import { Row} from "react-bootstrap";

const TrackRecord = ()=> {
    return (
        <div style={{padding: '70px 30px', backgroundColor: '#222222'}}>
            <div style={{paddingBottom:'30px'}}>
                <h1 style={{ 
                    fontSize: '1.5rem',
                    fontWeight: '700', 
                    margin: '0 auto', 
                    textAlign: 'center', 
                    paddingBottom:'20px',
                    color:'#F8C392',
                }}>
                   TRACK RECORD
                </h1>
                <p style={{textAlign:'center', color:'white', fontSize:'1.5rem', width:'70%', margin:'auto',}}>
                    We have a proven track record and we provide tailored Engineering Services across the globe
                </p>
            </div>
            <Row style={{padding:'10px', width:'80%', margin:'auto'}}>
                <img
                    alt='track_record'
                    width='100%'
                    style={{borderRadius:'5px'}}
                    src='https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/presence%2Ftrack-record.png?alt=media&token=de228333-a1bc-4423-bb69-aeec9ef98ad5'/>
            </Row>
        </div>
    );
}

export default TrackRecord;