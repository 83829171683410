import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const JobPostEN = (props) => {
    return (
        <div style={{padding:'4px 0px'}}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{backgroundColor:'#E94E1B'}}
                >
                    <Typography style={{
                        color:'white', 
                        fontWeight:'bold', 
                        fontSize:'1.1rem',
                        textAlign:'left',
                    }}>
                        {props.title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{
                    backgroundColor:'#1D70B8',
                    color:'white',
                    textAlign:'start',
                    wordWrap:'break-word',
                }}>
                    <Typography>
                    <p>We will be happy to have you on board! </p>
                    <p>{props.body}</p>
                    <h6>Your responsibility </h6>
                    {props.responsibility}
                    <h6>Your profile</h6>
                    {props.profile}
                    <h6>Windsy is the place to be!</h6>
                    <ul>
                        <li>… Creating what is next in the energy sector, means for us</li>
                        <li>The agility and flexibility of a start-up, with the safety of a large engineering group in the back</li>
                        <li>Pioneering future projects in the field of renewable energy</li>
                        <li>Driving Energization and digitization of the world with our services and products </li>
                        <li>Being part of a small enthusiastic team of thinkers and makers </li>
                        <li>Flat hierarchies in a modern environment provide a lot of creative freedom and a high degree of personal responsibility </li>
                        <li>International experience in a dynamic environment with direct customer contact </li>
                        <li>Flexible working models and attractive remuneration</li>
                        <li>Hybrid working model (office and mobile working from home)</li>
                        <li>A permanent full-time position</li>
                    </ul>                       
                    <p>
                        Please send us your CV and a cover letter as an application to <a style={{color:'orange'}}href="mailto:career@windsy.de">career@windsy.de</a>.
                    </p>
                    <p> 
                        By sending your application, you agree to the electronic storage of your personal data according to the <a style={{color:'orange'}} href="/company/data-protection" target="_blank" >data protections</a>
                    </p>
                    <p>
                        We appreciate to get your application - we value diversity! Be as you are!
                    </p>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default JobPostEN;