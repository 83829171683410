import React from 'react';
import './App.css';
import DueDiligence from './due-diligence/due-diligence';
import Home from './home/home-main';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FieldIssueResolution from './engg-services/field-issue-resolution';
import Industrialization from './engg-services/industrialization';
import SystemsEngineering from './engg-services/systems-engineering';
import SupplyChainSupport from './engg-services/supply-chain-support';
import ExpertAdvisory from './expert-advisory/expert-advisory';
import ProjectDevelopment from './project-dev/project-development';
import AboutUs from './company/about-us';
import Careers from './company/careers';
import Team from './company/team';
import ContactUs from './company/contact-us';
import ContactSales from './company/contact-sales';
import DataProtection from './security/data-protection';
import TermsOfService from './security/terms-of-service';
import PrivacyStatements from './security/privacy-statements';
import SalesTermsConditions from './security/sales-terms-conditions';

function App() {
  return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="expert-advisory" element={<ExpertAdvisory />} />
            <Route path="engg-services/field-issue-resolution" element={<FieldIssueResolution/>} />
            <Route path="engg-services/industrialization" element={<Industrialization/>} />
            <Route path="engg-services/systems-engineering" element={<SystemsEngineering/>} />
            <Route path="engg-services/supply-chain-support" element={<SupplyChainSupport/>} />
            <Route path="due-dilligence" element={<DueDiligence />} />
            <Route path="project-development" element={<ProjectDevelopment />} />
            <Route path="company/about-us" element={<AboutUs />} />
            <Route path="company/careers" element={<Careers />} />
            {/* <Route path="company/team" element={<Team />} /> */}
            <Route path="company/contact-us" element={<ContactUs />} />
              <Route path="company/contact-us/sales" element={<ContactSales />} />
            <Route path="company/data-protection" element={<DataProtection />} />
            <Route path="company/terms-of-service" element={<TermsOfService />} />
            <Route path="company/privacy-statements" element={<PrivacyStatements />} />
            <Route path="company/sales-terms-conditions" element={<SalesTermsConditions />} />
          </Routes>
        </Router>
      </div>
  );
}

export default App;
