import React, { useEffect, useState } from 'react';
import GetInTouchWithUs from '../common/get-in-touch';
import { Button } from "react-bootstrap";
import HomeHeader from './home-header';
import WhatWeOffer from './what-we-offer';
import WeAreEngaged from './we-are-engaged';
import TrackRecord from './track-record';
import WindsyAppBar from '../common/appbar';
import Footer from '../common/footer';
import AppBarAlternative from '../common/appbar-alternative';

const Home = ()=> {

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        window
        .matchMedia("(min-width: 768px)")
        .addEventListener('change', e => setMatches( e.matches ));
    }, []);


    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () =>{
       if(window.scrollY >= 200){
         setColorchange(true);
       }
       else{
         setColorchange(false);
       }
    };
    window.addEventListener('scroll', changeNavbarColor);

    return (
        <div>
        <WindsyAppBar
            changed = {colorChange}
        />
        <div>
        {matches ? (<div style={{position:'relative'}}>
                <video width="100%" autoPlay muted loop>
                    <source src="https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/windsy_intro.webm?alt=media&token=b5fc6633-6599-40d5-8d41-14b4d09cd99b" type="video/webm" />
                    Error Message
                </video>
                <Button 
                    variant="secondary"
                    style={{
                        position: 'absolute',
                        top: '75%',
                        left: '50%',                  
                        borderRadius:'20px',
                        fontWeight: '500',
                        fontSize: '12px',
                        padding: '10px 30px',
                        transform: 'translate(-50%, -50%)',
                        msTransform: 'translate(-50%, -50%)',
                        // backgroundColor: colorChange ? 'blue' : '#E94E1B',
                        backgroundColor: '#E94E1B',
                    }}
                    href="/engg-services/field-issue-resolution"
                >
                    Learn More
                </Button>
            </div>): (<div></div>)}</div>
            <HomeHeader />
            <WhatWeOffer />
            <WeAreEngaged />
            <TrackRecord />
            <GetInTouchWithUs />
            <Footer />
        </div>
    );
}

export default Home;