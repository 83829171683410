import React from 'react';
import WindsyAppBar from '../common/appbar';
import Footer from '../common/footer';
import ImageDivContainer from '../widgets/image-div-container';
import DivImageContainer from '../widgets/div-image-container';
import HeaderDiv from '../widgets/header-div';
import SubHeaderDiv from '../widgets/sub-header-div';
import ScopeOfWork from '../widgets/scope-of-work';
import GetInTouchWithUs from '../common/get-in-touch';
import DivColoredTwoColumn from '../widgets/div-colored-two-column';

const ProjectDevelopment = ()=> {
    return (
        <div>
            <WindsyAppBar changed="true"/>
            <div style={{height: '80px'}}></div>
            <HeaderDiv 
                title="Project Development"
                img = 'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fproject_dev.webp?alt=media&token=08c54e15-e79b-4aa8-ac42-4abcfff28f72'
            />
            {/* Sub Header Div */}
            <SubHeaderDiv
                body = {
                    <div>
                        <div>
                            We provide business case certainty for your planned wind asset.
                        </div>
                        <br></br>
                        <div>
                            We accompany all phases from the wind resource assessment until the O&M business model and asset management
                        </div>
                    </div>
                }
            />
            {/* Scope of Work */}
            <ScopeOfWork
                scope = "Services For Investors In Green Field Project, M&A & Debt Financing"
            />
            <DivImageContainer 
                header="Wind Farm Site Assessment"
                img={'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fproject_man%2Fproject%20management%20(2).jpeg?alt=media&token=0b16a1a0-cd91-44c6-8984-f979804cde61'}
                body={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px'}}>
                        <li>Analysis of earthquake zones and soil conditions</li>
                        <li>Analysis of grid connection, including grid capacity and availability</li>
                        <li>Estimation of losses and project uncertainty</li>
                        <li>Analysis of wind farm locations including topographic and orographic modelling</li>
                        <li>Analysis of closest reliable long-term Meteorologic data and spatial extrapolation</li>
                    </ul>
                }
            />

            <ImageDivContainer 
                header='Wind Farm Energy Assessment'
                img={'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fproduct_dev%2Fprod_dev_2.png?alt=media&token=5b6c4ce5-e870-4409-8750-419659bd1317'}
                body={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px', marginLeft:'auto', marginRight:'0'}}>
                        <li>Energy yield assessment of the best fit wind turbine technology</li>
                        <li>Optimum lay out to maximize energy production, minimize costs and wake losses</li>
                        <li>Optimum and least curtailment strategies to comply with regulatory restrictions</li>
                        <li>Evaluation of best fit wind turbine technologies based on site and wind conditions</li>
                        <li>P50, P75 and P90 Energy estimates for the wind farms (gross energy, net energy)</li>
                    </ul>
                }
            />
            <DivColoredTwoColumn 
                title='Business Case Certainity'
                header1='Financial Modelling'
                body1={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px', marginLeft:'auto', marginRight:'0'}}>
                        <li>Financial modelling for the lifetime of the wind farms</li>
                        <li>Evaluation of CAPEX cost for full EPC project including turbine technologies</li>
                        <li>Evaluation of OPEX costs for the operations and maintenance</li>
                        <li>Evaluate feed-in tariff, subsidies and tax exemptions</li>
                        <li>Evaluate regulations and obligations to grid operators and connections</li>
                    </ul>
                }
                header2='Permitting Process Assistance'
                body2={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px', marginLeft:'auto', marginRight:'0'}}>
                        <li>Analysis of available documentations for the wind parks</li>
                        <li>Preparation and coordination of all technical documentation required by authorities</li>
                        <li>Management and coordination of the meetings with authorities</li>
                    </ul>
                }
            />
            <DivImageContainer
                header='Construction Planning'
                img={'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fproduct_dev%2FIndustrialization.jpg?alt=media&token=66d9d814-010c-4cf2-8b52-665e91aac0ba'}
                body={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px'}}>
                        <li>Detailed planning for the construction, installation and commissioning phase</li>
                        <li>Project quality scorecard and control plan</li>
                        <li>Project HSE scorecard and control plan</li>
                        <li>Concept layout design considering turbine foundations, met mast foundations, substation, roads, irrigation channels, overhead electrical line, etc</li>
                        <li>Risk assessment and risk management including mitigation actions</li>
                    </ul>
                }
            />

            <ImageDivContainer 
                header='Supply Chain & Industrial Scheme Definition'
                img={'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fproject_man%2Fproject%20management.JPG?alt=media&token=8d5bb281-1372-4d6d-a8ad-a787844d6c96'}
                body={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px', marginLeft:'auto', marginRight:'0'}}>
                        <li>Critical path analysis</li>
                        <li>Risk assessment and risk management</li>
                        <li>Supply chain planning</li>
                        <li>Decision matrix for selecting suppliers</li>
                        <li>Setup and moderation of supplier panel for BoP, equipment and services</li>
                        <li>Call for tenders and management incl. negotiation of the requests for quotations</li>
                    </ul>
                }
            />
            <GetInTouchWithUs/>
            <Footer />
        </div>
    );
}
export default ProjectDevelopment;