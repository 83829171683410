import React from "react";
import { Container } from "react-bootstrap";

const SubHeaderDiv = (props) => {
    return (
        <div>
            <Container>
                <div style={{padding: '100px 0', margin:'auto'}}>
                    <p style={{fontSize: '1.8rem', fontWeight:'500'}}>
                        {props.body}
                    </p>
                </div>
            </Container>
        </div>
    );
}

export default SubHeaderDiv;