import React from "react";
import { Container } from "react-bootstrap";
import WindsyAppBar from "../common/appbar";
import Footer from "../common/footer";

const SalesTermsConditions = () => {
    return (
        <div>
            <WindsyAppBar changed="true"/>
            <div style={{height: '80px'}}></div>
            <Container style={{textAlign:'start', padding:'50px 20px'}}>
                <h1 style={{fontWeight:'700', fontSize:'3rem', color:'#E84E1B'}}>Allgemeine Gesch&auml;ftsbedingungen</h1> 
                <br></br>
   
                <p>1. Allgemeines, Geltungsbereich</p>

                <p> 1.1 Die Regelungen dieser Allgemeinen Gesch&auml;ftsbedingungen (im Folgenden &#8222;AGB&ldquo;) gelten f&uuml;r alle Lieferungen in ihrer zum Zeitpunkt der Bestellung g&uuml;ltigen Fassung.</p>
                <p>1.2 Diese AGB regeln den Gesch&auml;ftsverkehr mit privaten Kunden (im Folgenden &#8222;Verbraucher&ldquo;) und gewerblichen Kunden (im Folgenden &#8222;Unternehmer&ldquo;). Verbraucher im Sinne dieser AGB sind nat&uuml;rliche Personen, die das Rechtsgesch&auml;ft zu einem Zweck abschlie&szlig;en, der weder ihrer gewerblichen noch ihrer selbst&auml;ndigen beruflichen T&auml;tigkeit zugerechnet werden kann. Unternehmer im Sinne dieser AGB sind nat&uuml;rliche oder juristische Personen oder rechtsf&auml;hige Personengesellschaften, die bei Abschluss des Rechtsgesch&auml;fts in Aus&uuml;bung ihrer gewerblichen oder selbst&auml;ndigen beruflichen T&auml;tigkeit handeln. Sowohl Verbraucher, als auch Unternehmer k&ouml;nnen Kunden (im Folgenden &#8222;Kunden&ldquo;) der Firma Windsy GmbH (nachfolgend &#8222;Windsy&ldquo;) werden.</p>
                <p>1.3 Im Falle des Vertragsschlusses ist folgende Firma Vertragspartner:  &#8203;  Fa.Windsy GmbH, Stadtparkweg 12, 24106, Kiel  Email: Info@Windsy.de  HRB 25330 KI  Ust-ID DE327584085</p>
                <p>1.4 Es gelten ausschlie&szlig;lich diese AGB. Andere AGB, insbesondere AGB der Kunden werden auch dann nicht Bestandteil des Vertrages, wenn Ihnen nicht ausdr&uuml;cklich widersprochen worden ist.</p>
                <p>1.5 Diese AGB gelten auch bei zuk&uuml;nftigen Gesch&auml;ftsbeziehungen mit Unternehmern, ohne dass es hierf&uuml;r einer erneuten ausdr&uuml;cklichen Bezugnahme bedarf.</p>
                <br></br>

                <p>  2. Vertragsschluss</p>

                <p> 2.1 Der Vertragsabschluss erfolgt durch Annahme des Angebotes und Auftragserteilung.</p>
                <br></br>

                <p>  3. Zahlungsbedingungen und Preise</p>

                <p> 3.1 Die Zahlungsbedingungen werden Einzelvertraglich geregelt.</p>
                <p>3.2 Ab Beginn des Zahlungsverzugs h&auml;lt sich Windsy das Recht vor, Verzugszinsen zu berechnen. Die Verzugszinsen werden zus&auml;tzlich zum Kaufpreis geschuldet.</p>
                <p>3.3 F&uuml;r Kaufvertr&auml;ge mit Verbrauchern betr&auml;gt der Zinssatz 5 % f&uuml;r Verzugszinsen &uuml;ber dem Basiszinssatz.</p>
                <p>3.4 F&uuml;r Kaufvertr&auml;ge mit Unternehmern betr&auml;gt der Zinssatz 8 % f&uuml;r Verzugszinsen &uuml;ber dem Basiszinssatz</p>
                <p>3.5 Sofern keine Festpreisabrede getroffen wurde, bleiben angemessene Preis&auml;nderungen wegen ver&auml;nderter Lohn-, Material- und Vertriebskosten f&uuml;r Lieferungen, die 3 Monate oder sp&auml;ter nach Vertragsabschluss erfolgen, vorbehalten.</p>
                <br></br>

                <p>  4. Eigentumsvorbehalt</p>

                <p> 4.1 Bei Verbrauchern beh&auml;lt sich Windsy das Eigentum an der Ware bis zur vollst&auml;ndigen Bezahlung des Kaufpreises vor.</p>
                <p>4.2 Bei Unternehmern beh&auml;lt sich Windsy das Eigentum an der Ware bis zur vollst&auml;ndigen Begleichung aller Forderungen aus einer laufenden Gesch&auml;ftsbeziehung vor.</p>
                <p>4.3 Der Unternehmer ist berechtigt, die Ware im ordentlichen Gesch&auml;ftsgang weiter zu ver&auml;u&szlig;ern. Er tritt Windsy bereits jetzt alle Forderungen in H&ouml;he des Rechnungsbetrages ab, die ihm durch die Weiterver&auml;u&szlig;erung gegen einen Dritten erwachsen. Windsy nimmt die Abtretung an. Nach der Abtretung ist der Unternehmer weiter zur Einziehung der Forderung erm&auml;chtigt. Windsy beh&auml;lt sich jedoch vor, die Forderung selbst einzuziehen, sobald der Unternehmer seinen Zahlungsverpflichtungen nicht ordnungsgem&auml;&szlig; nachkommt und in Zahlungsverzug ger&auml;t.</p>
                <p>4.4 Wird die Kaufsache mit anderen, Windsy nicht geh&ouml;renden Gegenst&auml;nden untrennbar vermischt, so erwirbt Windsy das Miteigentum an der neuen Sache im Verh&auml;ltnis des Wertes der Kaufsache (Rechnungsbetrag) zu den anderen vermischten Gegenst&auml;nden zum Zeitpunkt der Vermischung. Erfolgt die Vermischung in der Weise, dass die Sache des Kunden als Hauptsache anzusehen ist, so gilt als vereinbart, dass der Kunde Windsy anteilm&auml;&szlig;ig Miteigentum &uuml;bertr&auml;gt. Der Kunde verwahrt das so entstandene Alleineigentum oder Miteigentum f&uuml;r Windsy. Verpf&auml;ndungen oder Sicherungs&uuml;bereignungen bez&uuml;glich der im (Mit-)Eigentum von Windsy stehenden Waren sind unzul&auml;ssig. Bei Zugriffen Dritter auf die Vorbehaltsware wird der Kunde auf das Eigentum von Windsy hinweisen und Windsy unverz&uuml;glich benachrichtigen.</p>
                <p>4.5 Bei Pf&auml;ndungen oder sonstigen Eingriffen Dritter hat der Kunde Windsy unverz&uuml;glich unter &Uuml;bergabe der f&uuml;r den Widerspruch notwendigen Unterlagen schriftlich zu unterrichten.</p>
                <p>4.6 Mit Antrag auf Er&ouml;ffnung des Insolvenzverfahrens, Zahlungseinstellung oder Durchf&uuml;hrung eines au&szlig;ergerichtlichen Einigungsverfahrens erl&ouml;schen das Recht zur Weiterver&auml;u&szlig;erung, Verwendung oder Einbau der Vorbehaltsware.</p>
                <p>4.7 Der Kunde ist verpflichtet die Vorbehaltsware pfleglich zu behandeln; insbesondere ist er verpflichtet, diese auf eigene Kosten gegen Feuer-, Wasser- und Diebstahlsch&auml;den ausreichend und zum Neuwert zu versichern. Sofern Wartungs- und Inspektionsarbeiten anfallen, hat der Kunde diese auf eigene Kosten rechtzeitig durchf&uuml;hren.</p>

                <br></br>

                <p>  5. Abnahme, Gefahren&uuml;bergang Photovoltaikanlage</p>
                <p>5.1 Die Abnahme erfolgt durch den Kunden nach betriebsfertiger Montage der Photovoltaikanlage. In sich geschlossene Teile des Werkes sind auf Verlangen von Windsy gesondert abzunehmen. Mit der Abnahme des Teils geht die Gefahr auf den Kunden &uuml;ber.</p>
                <p>5.2 &Uuml;ber die Abnahme ist ein Protokoll anzufertigen, dass von beiden Vertragsparteien zu unterzeichnen ist. Windsy kann sich bei der Abnahme und Unterzeichnung des Abnahmeprotokolls von einem von Windsy beauftragten Dritten vertreten lassen.</p>
                <p>5.3 Der Abnahme steht es gleich, wenn der Kunde die Photovoltaikanlage nicht innerhalb einer ihm von Windsy gesetzten angemessenen Frist abnimmt, obwohl der Kunde dazu verpflichtet ist. Weiter gilt die Abnahme als erfolgt, wenn der Kunde die Photovoltaikanlage in Gebrauch nimmt.</p>
                <br></br>

                <p>  6. Gew&auml;hrleistungsrechte Photovoltaikanlage</p>
                <p>6.1 Der Kunde hat Sachm&auml;ngel gegen&uuml;ber Windsy unverz&uuml;glich zu r&uuml;gen.</p>
                <p>6.2 Zeigt sich nach Abnahme ein Mangel der Installation der Photovoltaikanlage, ist Windsy zun&auml;chst zur Nachbesserung oder Nacherf&uuml;llung innerhalb einer angemessenen Frist berechtigt.</p>
                <p>6.3 M&auml;ngelanspr&uuml;che bestehen nicht bei nur unerheblicher Abweichung von der vereinbarten Beschaffenheit, bei nur unerheblicher Beeintr&auml;chtigung der Brauchbarkeit, bei nat&uuml;rlicher Abnutzung oder Verschlei&szlig; wie bei Sch&auml;den, die nach dem Gefahr&uuml;bergang infolge fehlerhafter oder nachl&auml;ssiger Behandlung, &uuml;berm&auml;&szlig;iger Beanspruchung, ungeeigneter Betriebsmittel, mangelhafter Bauarbeiten, ungeeigneten Baugrundes oder aufgrund besonderer &auml;u&szlig;erer Einfl&uuml;sse entstehen, die nach dem Vertrag nicht vorausgesetzt sind. Werden vom Kunden oder Dritten unsachgem&auml;&szlig; Instandsetzungsarbeiten oder &Auml;nderungen vorgenommen, so bestehen f&uuml;r diese und die daraus entstehenden Folgen ebenfalls keine M&auml;ngelanspr&uuml;che.</p>
                <p>6.4 Gew&auml;hrleistungsanspr&uuml;che verj&auml;hren, soweit gesetzlich zul&auml;ssig in einem Jahr nach Abnahme der Montagearbeiten.</p>
                <p>6.5 Die Leistungs- und Produktgarantien der Hersteller der verwendeten Photovoltaikmodule, Wechselrichter und Batterien mit dazugeh&ouml;rigem Geh&auml;use werden ausschlie&szlig;lich von den Herstellern gew&auml;hrt. Nach Ablauf der unter 6.3 genannten Verj&auml;hrungsfrist sind die Anspr&uuml;che aus der Leistungs- und/oder Produktgarantie des Herstellers direkt gegen diesen zu richten. </p>
                <br></br>

                <p> 7. Voraussetzuungen f&uuml;r die Montage und Inbetriebnahme der Photovoltaikanlage</p>
                <p>7.1 Der Kunde hat auf seine Kosten daf&uuml;r zu sorgen, dass die Montage, Aufstellung und/oder Inbetriebnahme vereinbarungsgem&auml;&szlig; begonnen und ohne Unterbrechung durchgef&uuml;hrt werden kann. Etwaige Kosten aufgrund von Verz&ouml;gerungen durch Nichterf&uuml;llung seitens des Kunden gem&auml;&szlig; 7.2 bis 7.5 sind vom Kunden zu tragen.</p>
                <p>7.2 Voraussetzung f&uuml;r den Beginn der Montage der PV-Anlage ist das Vorliegen der vertraglich festgelegten baulichen Erfordernisse und Pr&uuml;fungen f&uuml;r die Anlagenmontage. Es obliegt dem Kunden, das Vorliegen dieser baulichen Voraussetzungen auf seine Kosten vor Beginn der Montagearbeiten zu schaffen und Windsy diese nachzuweisen. (z. B. Pr&uuml;fung Dachstatik, ggf. Pr&uuml;fung auf Asbest)</p> 
                <p>7.3 Der Kunde erlaubt und sichert Windsy, deren Mitarbeitern und Erf&uuml;llungsgehilfen den uneingeschr&auml;nkten Zugang zu dem Montageplatz, insofern dies zur Erbringung der vertraglich geschuldeten Leistung erforderlich ist.</p>
                <p>7.4 Der Kunde stellt die Verf&uuml;gbarkeit eines Wasser- und Stromanschlusses f&uuml;r die Dauer der Montagearbeiten sicher. Etwaige kosten hierf&uuml;r tr&auml;gt der Kunde.</p>
                <p>7.5 Der Kunde stellt einen kostenlosen Lagerplatz f&uuml;r die zur Montage ben&ouml;tigten Materialien zur Verf&uuml;gung.</p>
                <p>7.6 Wenn nicht anders vereinbart, ist der Kunde verantwortlich f&uuml;r die f&uuml;r Montage und Betrieb der PV-Anlage erforderlichen Anzeigen und Genehmigungen bei z. B. der Baubeh&ouml;rde und sichert zu, dass diese vor Montagebeginn vorliegen. Es liegt nicht in der Verantwortung von Windsy, dies zu pr&uuml;fen.</p>
                <p>7.7 Windsy ist berechtigt, Teillieferungen und Teilleistungen zu erbringen, falls dies aufgrund von Verz&ouml;gerung in Materiallieferungen oder Abnahme der PV-Anlage n&ouml;tig ist.</p>
                <br></br>

                <p>   8. &Uuml;berlassene Unterlagen</p>

                <p> 8.1 An allen in Zusammenhang mit der Auftragserteilung dem Kunden &uuml;berlassenen Unterlagen &ndash; auch in elektronischer Form &ndash;, wie z. B. Kalkulationen, Zeichnungen etc., behalten wir uns Urheberrechte vor.</p>
                <br></br>

                <p>  9. Datenverarbeitung</p>

                <p> 9.1 Wir weisen darauf hin, dass Kundendaten f&uuml;r Windsy interne Zwecke gespeichert und als Lieferanschriften f&uuml;r Zulieferer verwendet werden.</p>
                <br></br>

                <p>  10. Widerrufsrecht</p>

                <p> 10.1 Der Verbraucher hat das Recht, den unterschriebenen Vertrag innerhalb von 14 Tagen zu widerrufen. Der Widerruft muss schriftlich an Windsy GmbH, Stadtparkweg 12, 24106, Kiel erfolgen. Die Frist hierf&uuml;r beginnt mit Erhalt des best&auml;tigten Auftrages.</p> 
                <p>10.2 Bei Widerruf nach Ablauf der Widerrufsfrist ist Windsy berechtigt, Schadensersatz i. H. von 1 % des Auftragswertes zu verlangen.</p>
                <br></br>
                <p>  11. Sonstiges</p>
                <p> 11.1 Dieser Vertrag und die gesamten Rechtsbeziehungen zwischen Windsy und dem Kunden unterliegen dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts (CISG). </p> 
                <p>11.2 Erf&uuml;llungsort und ausschlie&szlig;licher Gerichtsstand und f&uuml;r alle Streitigkeiten aus diesem Vertrag ist der Gesch&auml;ftssitz von Windsy, sofern sich aus der Auftragsbest&auml;tigung nichts anderes.</p>
                <p>11.3 Alle Vereinbarungen, die zwischen Windsy und dem Kunden zwecks Ausf&uuml;hrung dieses Vertrages getroffen werden, sind in diesem Vertrag schriftlich niedergelegt.</p>
            </Container>
            <Footer />
        </div>
    );
}

export default SalesTermsConditions;