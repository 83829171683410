import React from "react";
import WindsyAppBar from "../common/appbar";
import Footer from "../common/footer";
import GetInTouchWithUs from "../common/get-in-touch";
import { Container } from "react-bootstrap";
import WindsyLocations from "../widgets/locations";


const AboutUs = ()=> {
    return (
        <div>
            <WindsyAppBar changed="true"/>
            <div style={{height: '80px'}}></div>
            <Container style={{padding:'50px 30px'}}>
                <h1 style={{fontWeight:'700', fontSize:'3rem', color:'#E84E1B'}}>
                    We Are Team Windsy
                </h1>
                <p style={{fontWeight:'400', fontSize:'1.2rem', fontStyle:'italic', padding:'20px 0px'}}>"At Windsy we create with our partners what is next in the energy sector leveraging our inclusiveness, diversity and our values"
                </p>
                <div>
                    <img alt='about_us' width='50%' src='https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/launcher%2Fstartup_aboutus.svg?alt=media&token=e08bd214-6a7c-4baa-bf4c-0ce8182aa670' 
                    />
                </div>
                <h4 style={{
                    textAlign:'justify', 
                    textJustify:'inner-word',
                    padding:'20px 0'
                }}>
                    Creating what is next in the energy sector
                </h4>
                <p style={{
                    textAlign:'justify', 
                    textJustify:'inner-word',
                    fontWeight:'400',
                    fontSize:'1.2rem',
                }}>
                Windsy GmbH was founded in December 2019 and started operations in January 2020 with a selected number of experts and meanwhile maintains offices in Hamburg, Kiel and Dätgen. The major purpose of Windsy is to provide expert consultancy and engineering services to the wind industry. Windsy develops robust and reliable wind turbine products and systems and ensures their industrialization globally, at lowest cost. Windsy also designs, produces and sells innovative and disruptive products to tackle the growing demand and challenges in the operations and maintenance market of wind turbines. Windsy combines the extensive know-how and expertise of wind turbine suppliers with additional experience across industries. In addition, Windsy relies on their broad network of technology or industrial companies and institutes.
                </p>

                <h4 style={{
                    textAlign:'justify', 
                    textJustify:'inner-word',
                    padding:'20px 0'
                }}>
                    Inclusiveness and diversity
                </h4>
                <p style={{
                    textAlign:'justify', 
                    textJustify:'inner-word',
                    fontWeight:'400',
                    fontSize:'1.2rem',
                }}>
                Our people with their diverse backgrounds, experiences and cultures is our biggest differentiators to deliver superior quality services, products and technologies to our customers.
                </p>

                <h4 style={{
                    textAlign:'justify', 
                    textJustify:'inner-word',
                    padding:'20px 0'
                }}>
                    Values
                </h4>
                <p style={{
                    textAlign:'justify', 
                    textJustify:'inner-word',
                    fontWeight:'400',
                    fontSize:'1.2rem',
                }}>
                At Windsy we value honesty, transparency, open collaboration, innovation, effectiveness and efficiency in all we do with customers, partners and among ourselves.
                </p>
                <img
                    style= {{
                        padding: '30px 0'
                    }}
                    alt='sh_sponsored'
                    width='100%'
                    src='https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/sh_sponsored.jpg?alt=media&token=23b3fe4c-9eeb-4ba1-a0fb-e7ca541d70dd'
                />

                <p style={{
                    textAlign:'justify', 
                    textJustify:'inner-word',
                    fontWeight:'400',
                    fontSize:'1.2rem',
                }}>
                Windsy was awarded public funding for its product innovations:<br /><br />
                
                In this project Windsy GmbH will develop an innovative monitoring and assisting system for wind turbines. The system offers a wide variety of control, sensing and software components as well as digital products in order to monitor wind turbines’ health and safety as well as to assist service operators and to safeguard them during operations and maintenance. It enables wind park operators to minimize their operations and maintenance cost as well as to maximize the availability of their turbines through their entire lifetime, thus, to significantly reduce the cost of energy.
                </p>

                <WindsyLocations />
            </Container>
            <GetInTouchWithUs />
            <Footer />
        </div>
    );
}
export default AboutUs;