import React from "react";
import { Container } from "react-bootstrap";

const ScopeOfWork = (props) => {
    return (
        <div style={{backgroundColor: '#F5F5F5', padding: '100px 0'}}>
            <Container>
                <h1 style={{fontSize: '3rem', fontWeight: '700', padding:'0px 30px'}}>
                    {props.scope}
                </h1>
            </Container>
        </div>
    );
}

export default ScopeOfWork;