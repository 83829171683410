import React from "react";
import { Container, Card, Col, Button, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

const GetInTouchWithUs = () => {
    const navigate = useNavigate();

    return (
        <Container style={{
            padding:'50px 10px',
        }}>
            <Card style={{
                display:'flex', 
                //height: '350px', 
                alignItems:'center', 
                justifyContent:'center',
                verticalAlign: 'middle',
                borderRadius: '24px',
                boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                padding:'100px 50px',
            }}>
                <div>
                    <Card.Title style={{
                        fontSize: '3rem',
                        fontWeight: '700',
                    }}>
                        Get in touch with us
                    </Card.Title>
                    <Card.Text style={{
                        fontSize: '1.3rem',
                        fontWeight: '300',
                        padding:'20px 0px'
                    }}>
                        Use the contact form below or write us an email - we are looking forward to it.
                    </Card.Text>
                    <Row className="justify-content-md-center">
                        <Col style={{padding:'10px'}}>
                            <Button 
                                variant="flat"
                                href="/company/contact-us"
                                style={{
                                    borderRadius:'18px',
                                    fontWeight: '600',
                                    fontSize: '1rem',
                                    padding: '8px 24px',
                                    width:'170px',
                                    backgroundColor:'#E94E1B',
                                    color:'white',
                                }}
                            >
                                Contact us
                            </Button>
                        </Col>
                        <Col style={{padding:'10px'}}>
                            <Button 
                                variant="outline-primary"
                                href='mailto:info@windsy.de?subject=Interested in your services'
                                style={{
                                    borderRadius:'18px',
                                    fontWeight: '600',
                                    fontSize: '1rem',
                                    padding: '8px 24px',
                                    width:'170px',
                                }}
                            >
                                info@windsy.de
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Card>
        </Container>
    );
}

export default GetInTouchWithUs;