import React from "react";
import WindsyAppBar from "../common/appbar";
import Footer from "../common/footer";
import GetInTouchWithUs from "../common/get-in-touch";
import DivImageContainer from "../widgets/div-image-container";
import HeaderDiv from "../widgets/header-div";
import ImageDivContainer from "../widgets/image-div-container";
import ScopeOfWork from "../widgets/scope-of-work";
import SubHeaderDiv from "../widgets/sub-header-div";

const SupplyChainSupport = ()=> {
    return (
        <div>
            <WindsyAppBar changed="true"/>
            <div style={{height: '80px'}}></div>
            <HeaderDiv 
                title="Industrialization & Supply Chain Support"
                img = 'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fopmain.webp?alt=media&token=e411e6ec-e761-4e3d-914a-9defbe4ce9a3'
            />
            <SubHeaderDiv
                body = {
                    <div>
                        <div>
                            A bill of material that is ready for ordering, manufacturing, installation and servicing. No gaps in documentation and a well standardized modular setup. Our engineers accompany the first product at site and during the first operations
                        </div>
                        <br></br>
                        <div>
                        This is what we call failure free new product introduction. No surprise! Neither for the customer nor during first operations. And if there is still a failure! We resolve it fast and systematically
                        </div>
                    </div>
                }
            />
            {/* Scope of Work */}
            <ScopeOfWork
                scope = "Expert Tailored Engineering Services"
            />
            <DivImageContainer
                header='Serialization & Standardization'
                img={'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fsystemindus%2FIndustrialization2.JPG?alt=media&token=3f732163-63ea-43c3-833f-db653245168b'}
                body={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px'}}>
                        <li>Modularization and standardization</li>
                        <li>Localization of materials and tools</li>
                        <li>Failure free product introduction</li>
                        <li>Digitization of manuals, instructions and product documentation</li>
                    </ul>
                }
            />
            <ImageDivContainer
                header='Value Chain Optimization'
                img={'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fsystemindus%2FIndustrialization1.JPG?alt=media&token=143a49c0-e9d8-42ad-9ec8-89a91326d23b'}
                body={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px', marginLeft:'auto', marginRight:'0'}}>
                        <li>Value chain analysis and cost out</li>
                        <li>Supply chain support</li>
                        <li>Efficient maintenance concepts</li>
                        <li>Fast and sustainable failure resolution along 8D process</li>
                        <li>Optimization of manufacturing, transport and installation scenarios</li>
                    </ul>
                }
            />
            <GetInTouchWithUs />
            <Footer />
        </div>
    );
}
export default SupplyChainSupport;