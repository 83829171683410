import React, { useState } from "react";
import '../widgets/widget-styles.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { useEffect } from "react";

const DivImageContainer = (props)=> {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        window
        .matchMedia("(min-width: 768px)")
        .addEventListener('change', e => setMatches( e.matches ));
    }, []);
    
    return (
    <div>
        {matches ? (
        <div style={{display:'flex', justifyContent:'center'}}>
        <div style={{width:'90%', padding: '50px 20px'}}>
            <div style={{display:'flex', justifyContent:'space-between',width:'100%'}}>
                <div  style={{display:'flex', justifyContent:'start', marginLeft:'auto', marginRight:'0', width:'50%'}}>
                    <div style={{width:'80%'}}>
                        <h1 style={{ fontSize: '3rem', fontWeight:'bold', margin: '0 auto', textAlign: 'start', paddingBottom:'20px'}}>
                            {props.header}
                        </h1>
                        {props.body}
                    </div>
                </div>
                <div style={{display:'flex', justifyContent:'end', width:'50%'}}>
                    <img src={props.img} style={{width:'80%', height:'45vh',minHeight:'400px',  borderRadius: 10}} alt="img" loading="lazy" />
                </div>
            </div>
        </div>
        </div>)
            : (
            <div style={{padding:'20px 30px 0px 30px', textAlign:'left'}}>
                <div  style={{marginLeft:'auto', marginRight:'0'}}>
                    <h1 style={{ fontSize: '3rem', fontWeight:'bold', margin: '0 auto', textAlign: 'start', paddingBottom:'20px'}}>
                        {props.header}
                    </h1>
                    {props.body}
                </div>
                <img src={props.img} style={{width: '100%', minHeight:'300px', borderRadius: 10}} alt="img" loading="lazy" />
                <div style={{padding:'30px 0px 0px 0px'}}>
                    <Button                                    
                        variant="flat"
                        style={{
                            backgroundColor:'#E94E1B',
                            color:'white',
                            fontWeight:'500',
                        }}
                        href="/company/contact-us">
                        Contact us
                    </Button>
                </div>
            </div>
            )}
        </div>

    );
}

export default DivImageContainer;