import React from "react";
import { Row } from "react-bootstrap";
import TextCard from "../widgets/text-cards";

const WeAreEngaged = ()=> {
    return (
        <div style={{padding: '70px 30px', backgroundColor: '#E94E1B'}}>
            <div style={{paddingBottom:'30px'}}>
                <h1 style={{ 
                    fontSize: '1.5rem',
                    fontWeight: '700', 
                    margin: '0 auto', 
                    textAlign: 'center', 
                    paddingBottom:'20px',
                    color:'#F8C392',
                }}>
                    WE ARE ENGAGED IN
                </h1>
{/*                 <p style={{textAlign:'center', color:'white', fontSize:'1.5rem', width:'70%', margin:'auto',}}>
                Best in class services and solutions through entire life cycle of wind farms by bridging technical, commercial and financial disciplines
                </p> */}
            </div>
            <Row style={{padding:'10px', width:'80%', margin:'auto'}}>
                <TextCard title='O&M' body='+ 1200 MW' />
                <TextCard title='Installing & Commissioning' body='+ 540 MW' />
                <TextCard title='Technical Due Diligence' body='+ 30 Due Diligences' />
                <TextCard title='Systems Engineering & Industrialization' body='+ 52 Projects' />
                <TextCard title='Advisory & Technical Services' body='+ 20 Customers' />
            </Row>
        </div>
    );
}

export default WeAreEngaged;
