import React from "react";
import WindsyAppBar from "../common/appbar";
import Footer from "../common/footer";
import GetInTouchWithUs from "../common/get-in-touch";
import DivImageContainer from "../widgets/div-image-container";
import DivOneRowTwoColumns from "../widgets/div-one-row-two-columns";
import HeaderDiv from "../widgets/header-div";
import ImageDivContainer from "../widgets/image-div-container";
import ScopeOfWork from "../widgets/scope-of-work";
import SubHeaderDiv from "../widgets/sub-header-div";

const FieldIssueResolution = ()=> {
    return (
        <div>
            <WindsyAppBar changed="true"/>
            <div style={{height: '80px'}}></div>
            <HeaderDiv 
                title="Field Issue Resolution"
                img = 'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fturbine.webp?alt=media&token=7e4e464e-941d-4aaa-a9c0-3f639d1ccf80'
            />
            <SubHeaderDiv
                body = "Windsy has setup a dedicated technical support team to resolve field issues reliable and with speed to reduce your O&M cost. This includes a large set of different technical services and our latest O&M products!"
            />
            {/* Scope of Work */}
            <ScopeOfWork
                scope = "Expert Tailored Engineering Services"
            />
            <DivImageContainer
                header='Services We Offer'
                img={'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Ffield_issue%2Frepair.jpg?alt=media&token=bf2388e8-9125-4071-9226-6f9c854c56a1'}
                body={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px'}}>
                        <li>Service Bill of Materials (BOMs), Master data and supply chain data</li>
                        <li>Tool design and service tool BOMs</li>
                        <li>Critical material supply</li>
                        <li>Field issue resolution, applying 8D</li>
                        <li>Major and critical component exchange documentation</li>
                        <li>Immediate and permanent corrective actions and technical solutions</li>
                    </ul>
                }
            />
            <DivOneRowTwoColumns
                body={
                    <ul style={{textAlign: 'start', fontSize: 30, padding: '20px 40px'}}>
                        <li>Risk and reliability assessment</li>
                        <li>Root cause analysis</li>
                        <li>Retrofit and repair design</li>
                        <li>End of warranty inspections</li>
                        <li>Onsite inspections</li>
                    </ul>
                }
            />
            <DivImageContainer
                header='Services We Offer'
                img={'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Ffri.webp?alt=media&token=0d49fd7e-9b5a-4eaa-825e-8ac0afc72c05'}
                body={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px'}}>
                        <li>Preventive actions to avoid re-occurrence of issues</li>
                        <li>Detailed technical documentation for optimized service</li>
                        <li>Engineering of localized components, supplier selection</li>
                        <li>Performance improvements of wind turbines</li>
                        <li>Turbine walk-down</li>
                    </ul>
                }
            />
            <ImageDivContainer
                header='Busbar Replacement By Power-Cable-System'
                img={'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Ffield_issue%2Fbusbar_senvion.png?alt=media&token=53a580c3-51eb-4fa5-9e9a-4859f57fc461'}
                body={
                    <div style={{textAlign:'justify'}}>
                        <p style={{textAlign:'justify',fontSize: 20}}>
                            Busbars of Senvion wind turbines are known to be error-prone. And busbar replacements can be expensive.The Windsy power cable solution can be tailored to your needs and replaces the busbar system with a reliable and proven cable solution
                        </p>
                        <h4>Advantages: </h4>
                        <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px', marginLeft:'auto', marginRight:'0'}}>
                            <li>Component and system robustness</li>
                            <li>Cost and service reduction</li>
                            <li>Material availability on global market</li>
                        </ul>
                        <h4>Offered Packages: </h4>
                        <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px', marginLeft:'auto', marginRight:'0'}}>
                            <li>Engineering package tailored to your turbine</li>
                            <li>Plug & play kit including all necessary materials and documents</li>
                            <li>Operation of project, supply chain and installation from kick-off meeting to certified turbine</li>
                        </ul>
                    </div>
                }
            />
            <GetInTouchWithUs />
            <Footer />
        </div>
    );
}
export default FieldIssueResolution;