import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

const HomeHeader = ()=> {

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        window
        .matchMedia("(min-width: 768px)")
        .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <div>
        {matches ? (
            <div style={{ display:'flex', justifyContent:'space-between', padding:'50px 10px', width:'90%', margin:'auto'}}>
                <Col xs={20} sm={14} md={6} lg={7}>
                    <Col sm={14} md={18} lg={8} style={{padding:'0px 20px'}}>
                        <h1 style={{ 
                            fontSize: '2rem',
                            fontWeight: '700', 
                            margin: '0 auto', 
                            textAlign: 'start', 
                            paddingBottom:'20px'
                        }}>
                            Experts in Wind Farm Operations and Asset Management
                        </h1>
                        <p style={{textAlign:'start', fontSize:'1.1rem'}}>
                        WINDSY is a leading professional service firm for wind farm operations and asset management at all stages of your projects. We provide advisory, technical services and solutions for the development, construction of projects and for the operations and maintenance of wind energy assets. We also provide commercial and technical due diligence for your business case certainty.
                        </p>
                    </Col>
                </Col>
                <Col xs={30} sm={30} md={6} lg={5}>
                    <img 
                        src= 'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fhomepage.webp?alt=media&token=2da31062-ce4d-4007-84d6-dad0679a31a1'
                        style={{width: '100%', minWidth:'400px', minHeight: '40vh', borderRadius: 10}}
                        alt="home_header_img"
                        loading="lazy"
                        object-fit= "contain"
                    />
                </Col>
            </div>)
            : 
            (
            <Row style={{padding:'20px 10px', width:'100%', margin:'auto'}}>
                <Col xs={20} sm={14} md={8} lg={8}>
                    <Col sm={14} md={8} lg={8} style={{padding:'10px'}}>
                        <h1 style={{ 
                            fontSize: '2rem',
                            fontWeight: '700', 
                            margin: '0 auto', 
                            textAlign: 'start', 
                            paddingBottom:'20px'
                        }}>
                            Experts in Wind Farm Operations and Asset Management
                        </h1>
                        <p style={{textAlign:'start', fontSize:'1.1rem'}}>
                        WINDSY is a leading professional service firm for wind farm operations and asset management at all stages of your projects. We provide advisory, technical services and solutions for the development, construction of projects and for the operations and maintenance of wind energy assets. We also provide commercial and technical due diligence for your business case certainty.
                        </p>
                    </Col>
                </Col>
                <Col xs={30} sm={30} md={2} lg={4}>
                    <img 
                        src= 'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fhomepage.webp?alt=media&token=2da31062-ce4d-4007-84d6-dad0679a31a1'
                        style={{width:'100%', borderRadius: 10}}
                        alt="home_header_img"
                        loading="lazy"
                        object-fit= "contain"
                    />
                </Col>
            </Row>
            )}
        </div>
    );
}

export default HomeHeader;