import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Column,
    FooterLink,
    Heading,
  } from "./footer-styles";

const Footer = () => {

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        window
        .matchMedia("(min-width: 768px)")
        .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <div>
        {matches ? (
        <div>
            <div className="customFooter">
            </div>
            <div style={{
                    backgroundColor: '#F5F5F5',
                    padding: '30px 0',
                }}>
                <Container style={{position: 'relative'}}>
                    <Row>
                        <Column style={{
                        color: 'blue',
                        }}>
                            <Heading>Engineering Services</Heading>
                            <FooterLink href="/engg-services/field-issue-resolution">Field Issue Resolution</FooterLink>
                            <FooterLink href="/engg-services/industrialization">Industrialization</FooterLink>
                            <FooterLink href="/engg-services/systems-engineering">Systems Engineering</FooterLink>
                            <FooterLink href="/engg-services/supply-chain-support">Supply Chain Support</FooterLink>
                        </Column>
                        <Column>
                            <Heading>Others</Heading>
                            <FooterLink href="/expert-advisory">Expert Advisory</FooterLink>
                            <FooterLink href="/due-dilligence">Due Diligence</FooterLink>
                            <FooterLink href="/project-development">Project Development</FooterLink>
                        </Column>
                        <Column>
                            <Heading>Company</Heading>
                            <FooterLink href="/company/about-us">About us</FooterLink>
                            <FooterLink href="/company/careers">Careers</FooterLink>
                            {/* <FooterLink href="/company/team">Team</FooterLink> */}
                            <FooterLink href="/company/contact-us">Contact us</FooterLink>
                            {/* <FooterLink href="https://www.windsy-solar.de/" target='_blank'>Solar</FooterLink> */}
                        </Column>
                        <Column style={{fontSize:'12px', textAlign:'right'}}>
                            <FooterLink href="/">
                                <img alt='windsy' width='100%' src='https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/launcher%2Fwindsy.png?alt=media&token=0cba3a23-67c7-475a-b2f1-3e40a5e0f06e'/>
                            </FooterLink>
                            <FooterLink href="https://www.vulcain-eng.com" target='_blank'>
                                <img alt='vulcain' width='100%' src='https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/vulcain_logo.svg?alt=media&token=842acfa5-311f-46e3-ba6d-6ae588a9b452'/>
                            </FooterLink>
                            <FooterLink href="/"></FooterLink>
                            {/* <FooterLink href="/company/sales-terms-conditions">Sales Terms & Conditions</FooterLink> */}
                            <FooterLink href="/company/privacy-statements">Privacy Statements</FooterLink>
                            {/* <FooterLink href="/company/terms-of-service">Terms of Service</FooterLink> */}
                        </Column>
                    </Row>
                    <p style={{fontSize:'12px', textAlign:'right', marginRight:'30px'}}>Copyright © 2022 Windsy. All rights reserved</p>
                </Container>
            </div>
        </div>) :(
        <div style={{
            backgroundColor: '#F5F5F5',
            padding: '30px 0',
        }}>
            <div>
                <Row>
                    <Column style={{fontSize:'12px', textAlign:'left', width:'100%'}}>
                        <FooterLink href="/">
                            <img alt='windsy' width='50%' src='https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/launcher%2Fwindsy.png?alt=media&token=0cba3a23-67c7-475a-b2f1-3e40a5e0f06e'/>
                        </FooterLink>
                        <FooterLink href="https://www.vulcain-eng.com" target='_blank'>
                            <img alt='vulcain' width='50%' src='https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/vulcain_logo.svg?alt=media&token=842acfa5-311f-46e3-ba6d-6ae588a9b452'/>
                        </FooterLink>
                        {/* <FooterLink href="/company/sales-terms-conditions">Sales Terms & Conditions</FooterLink> */}
                        <FooterLink href="/company/privacy-statements">Privacy Statements</FooterLink>
                        {/* <FooterLink href="/company/terms-of-service">Terms of Service</FooterLink> */}
                        <p style={{fontSize:'10px', textAlign:'left'}}>Copyright © 2022 Windsy. All rights reserved</p>
                    </Column>
                </Row>
            </div>
        </div>
        )
    }
    </div>
    );
}

export default Footer;