import React from "react";
import ImageDivContainer from '../widgets/image-div-container';
import DivImageContainer from '../widgets/div-image-container';
import HeaderDiv from '../widgets/header-div';
import SubHeaderDiv from '../widgets/sub-header-div';
import ScopeOfWork from '../widgets/scope-of-work';
import GetInTouchWithUs from '../common/get-in-touch';
import WindsyAppBar from '../common/appbar';
import Footer from '../common/footer';

const ExpertAdvisory = ()=> {
    return (
        <div>
            <WindsyAppBar changed="true"/>
            <div style={{height: '80px'}}></div>
            <HeaderDiv 
                title="Technical Due Diligence & Expert Advisory"
                img = 'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/home%2Fduediligence.webp?alt=media&token=48c91d9e-d8ff-4f69-9e34-39775b25aec1'
            />
            {/* Sub Header Div */}
            <SubHeaderDiv
                body = "We are the trusted partner in advisory and due diligence our customer have searched for. Our wind turbine OEM DNA helps us to nail down all technical and financial risks and to come with the right mitigation recommendation"
            />
            {/* Scope of Work */}
            <ScopeOfWork
                scope = "Expert Tailored Consultancy Services"
            />
            <DivImageContainer 
                img={'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/due_dilli%2F1.jpg?alt=media&token=d73545fe-addd-4da6-8502-7b3461793585'}
                body={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px'}}>
                        <li>Executive summary of risks, findings, and recommendations</li>
                        <li>Risks, mitigation's, and opportunities</li>
                        <li>Noise and grid compliance</li>
                        <li>IP portfolio assessment, risks, and disputes</li>
                        <li>Critical business and technology risks</li>
                        <li>Curtailment analysis</li>
                    </ul>
                }
            />

            <ImageDivContainer 
                img={'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/due_dilli%2F____.jpg?alt=media&token=7e3cb2a4-4e56-4e4f-b67b-fa9c9824b5bb'}
                body={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px', marginLeft:'auto', marginRight:'0'}}>
                        <li>O&M performance and risks</li>
                        <li>Analysis of closest reliable long-term Meteorologic data</li>
                        <li>Energy yield assessment based on site and wind conditions</li>
                        <li>P50, P75 and P90 Energy estimates for the wind farm (gross energy, net energy)</li>
                        <li>Estimation of losses and project uncertainty</li>
                        <li>Analysis of Performance, robustness, and reliability of wind turbine technologies</li>
                    </ul>
                }
            />

            <DivImageContainer 
                img={'https://firebasestorage.googleapis.com/v0/b/windsy-solve.appspot.com/o/due_dilli%2Fproject%20management.jpeg?alt=media&token=8cdc358a-a176-461c-be6d-72f972b768a1'}
                body={
                    <ul style={{textAlign: 'start', fontSize: 20, padding: '0 0 0 20px'}}>
                        <li>Analysis of grid connection, including grid capacity and availability</li>
                        <li>Turbine walk down, End of warranty inspections, on-site inspections</li>
                        <li>Product portfolio evaluation and positioning in the wind industry market</li>
                        <li>Analysis of regulatory and access restrictions</li>
                    </ul>
                }
            />
            <GetInTouchWithUs/>
            <Footer />
        </div>
    );
}
export default ExpertAdvisory;